import { create } from '@storybook/theming'
import logo from '../assets/images/turbine-logo-storybook.svg'

export default create({
	base: 'light',

	// colorPrimary: 'hotpink',
	// colorSecondary: 'deepskyblue',
	//
	// // UI
	appBg: '#e9efff',
	appContentBg: 'white',
	// appBorderColor: 'grey',
	appBorderRadius: 8,
	//
	// // Typography
	// fontBase: '"Open Sans", sans-serif',
	// fontCode: 'monospace',
	//
	// // Text colors
	textColor: 'black',
	textInverseColor: 'rgba(255,255,255,0.9)',
	//
	// // Toolbar default and active colors
	// barTextColor: 'silver',
	// barSelectedColor: 'black',
	// barBg: 'hotpink',
	//
	// // Form colors
	// inputBg: 'white',
	// inputBorder: 'silver',
	// inputTextColor: 'black',
	// inputBorderRadius: 4,
	//
	brandTitle: 'Turbine design systems',
	brandUrl: 'https://fastwork.co',
	brandImage: logo,
	// brandTarget: '_self',
})
